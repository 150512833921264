<template>
  <div
    v-if="!!trackInfo || !!effectTime"
    class="order-list-card"
  >
    <div
      v-if="!!trackInfo"
      v-tap="{
        id: '1-20-1-63',
        data: logisticsTrackAnalysis
      }"
      class="order-list-track"
      @click.stop="handelTrackClick($event)"
    >
      <div class="order-list-track__left">
        <img
          class="order-list-track__left__logo"
          :src="trackInfo.status_logo"
          alt=""
        />
      </div>
      <div class="order-list-track__content">
        <ClientOnly>
          <div class="order-list-track__content__title">
            <span class="order-list-track__content__title-status">
              {{ trackInfo.status_desc }}
            </span>
            <span class="order-list-track__content__title-time">
              {{ handlerDate(trackInfo.time) }}
              {{ handleShortTime(trackInfo.time) }}
            </span>
          </div>
        </ClientOnly>
        <div
          class="order-list-track__content__info"
          v-html="trackInfo.desc"
        >
        </div>
      </div>
      <div class="order-list-track__right">
        <i class="suiiconfont sui_icon_more_right_16px"></i>
      </div>
    </div>
    <ClientOnly>
      <div
        v-if="!!effectTime"
        class="order-list-card__transport-ime"
        :class="{ 'is-show-gutter': !!trackInfo }"
        v-html="effectTime"
      ></div>
    </ClientOnly>
  </div>
</template>

<script>
import orderLogic from 'public/src/pages/common/orderLogic'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { mapState } from 'vuex'
import { template } from '@shein/common-function'
import ClientOnly from 'vue-client-only'

let { langPath } = gbCommonInfo
export default {
  name: 'OrderListTrack',
  components: {
    ClientOnly
  },
  directives: { tap, expose },
  props: {
    order: {
      type: Object,
      default: function () {
        return {}
      }
    },
    language: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapState('orderList', ['publicLocalData', 'abtInfos']),
    trackInfo () {
      return this.order?.track_info || null
    },
    effectTime () {
      let effectList = this.order?.max_transport_time_detail?.effectList || []
      return effectList.reduce((prev, next) => {
        let { shippingTimeDesc, timeForFillDesc = [] } = next || {}
        if (timeForFillDesc?.length) {
          let timeForFillDescMap = timeForFillDesc.map(item => `${this.handlerDate(item)}`)
          let text = template(...timeForFillDescMap, shippingTimeDesc || '')
          return prev + text 
        } else {
          return prev + (shippingTimeDesc || '')
        }
      }, '')
    },
    logisticsTrackAnalysis() {
      return {
        time: this.abtInfos?.newAbtTestConfig?.['OrderListTime'] == 'on' ? 1 : 0,
        billno: this.order.billno,
        reference_number: '-'
      }
    }
  },
  methods: {
    handlerDate(date) {
      return orderLogic.orderDateFormat(date, false, this.publicLocalData)
    },
    handleShortTime(time) {
      return orderLogic.orderCompleteShortDateFormat(time, this.publicLocalData)
    },
    handelTrackClick(event) {
      let { parentElement, tagName } = event?.target || {}
      if (tagName === 'A' || parentElement?.tagName === 'A') return
      this.$router.push(`${langPath}/user/orders/track/${this.order.billno}`)
    }
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type,  selector-nested-pattern  */
.line-clamp-2() {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.order-list-card {
  width: 702/75rem;
  margin: 0 auto 0.32rem auto;
  padding: 20/75rem;
  font-family: 'SF UI Text';
  color: #000000;
  box-sizing: border-box;
  background: #f6f6f6;

  &__transport-ime {
    .line-clamp-2();
    font-size: 11px;
    line-height: 13px;
    color: #666666;

    &.is-show-gutter {
      margin-top: 16/75rem;
    }
  }
}

.order-list-track {
  display: flex;

  &__left {
    padding-top: 3/75rem;

    &__logo {
      line-height: 1;
      font-size: 16px;
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    width: 566/75rem;
    margin-left: 11/75rem;
    margin-right: 12/75rem;

    &__title {
      font-size: 0;
      font-weight: 600;

      &-status {
        display: inline-block;
        vertical-align: bottom;
        max-width: 100%;
        margin-right: 8/75rem;
        font-size: 12px;
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-time {
        display: inline-block;
        vertical-align: bottom;
        font-size: 10px;
        line-height: 12px;
        margin-top: 2px;
      }
    }

    &__info {
      .line-clamp-2();
      font-size: 11px;
      line-height: 13px;
      margin-top: 8/75rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    .suiiconfont {
      color: #959595;
    }
  }
}
</style>
