<template>
  <div>
    <!-- used vue -->
    <section class="fixed-common-header order-list-z-header">
      <s-title-nav
        :title="language.SHEIN_KEY_PWA_16117"
        class="order-list-header"
      >
        <template slot="preblock">
          <s-title-nav-item
            icon-class="suiiconfont sui_icon_nav_back_24px"
            @click.native="goBack"
          />
          <s-title-nav-item
            v-tap="{ id: '1-20-1-162' }"
            icon-class="sui_icon_nav_search_24px suiiconfont"
            @click.native="orderSearchOpen"
          />
        </template>
        <template slot="endblock">
          <router-link
            v-if="robotShow"
            v-tap="{ id: '1-20-1-163', data: { type: 'customer_service' } }"
            :to="jumpToRobot()"
          >
            <SupportIcon 
              :un-read-chat-num="unReadChatNum"
            >
              <sui_icon_pay_support_24px
                :is-rotate="publicLocalData.GB_cssRight"
                size="24px"
              />
            </SupportIcon>
          </router-link>
          <s-popover
            v-model="showDeleteHistoryPopover"
            class="delete-popover"
            trigger-type="user"
            :append-to-body="false"
            :content="language.SHEIN_KEY_PWA_18260"
            :prop-style="popoverStyle"
            placemen="bottom-end"
          >
            <span
              slot="reference"
              v-tap="{ id: '1-20-1-20' }"
              v-expose="{ id: '1-20-1-166' }"
              @click="toRecycle"
            >
              <i class="suiiconfont sui_icon_nav_delete_24px"></i>
            </span>
          </s-popover>
        </template>
      </s-title-nav>
      <div
        v-show="orderSearchMsg.show"
        class="macc-order-search"
      >
        <div class="search-title">
          <div class="search-input">
            <input
              v-model="orderSearchMsg.content"
              type="text"
              :placeholder="language.SHEIN_KEY_PWA_16581"
              maxlength="100"
              autocomplete="off"
              class="order-search-input"
              @keyup.enter="orderSearch"
            />
          </div>
          <a
            class="search-cancel"
            @click="orderSearchCancel"
          >
            {{ language.SHEIN_KEY_PWA_15729 }}
          </a>
        </div>
      </div>
    </section>

    <div
      v-cloak
      class="order-list-top-pad"
      :class="{ 'oreder-list-top-pad': orderSearchMsg.show }"
    >
      <!-- 页面loading -->
      <ClientOnly>
        <LazyMount mount-prop-name="show">
          <s-loading
            :show="pageLoading"
            type="newpage"
            :container-cover-style="{ position: 'fixed', height: '100%', width: '100%', background: '#fff' }"
          />
        </LazyMount>
      </ClientOnly>

      <!-- 提示公告 -->
      <list-tips v-if="!pageLoading" />

      <!-- 列表容器 -->
      <div
        v-show="!pageLoading"
        v-infinite-scroll="loadMore"
        class="j-scroll-container"
        infinite-scroll-disabled="status.loading"
        infinite-scroll-nodata="noMoreData"
        infinite-scroll-distance="300"
      >
        <!-- 无网提示 -->
        <SNetworkErrorAlert
          class="network-error"
          :description="language.SHEIN_KEY_PWA_31743"
          @online-change="onlineChange"
        />

        <!-- 订单状态 -->
        <s-tab
          v-if="!pageLoading && !orderSearchMsg.show"
          :value="status_type"
          :type="'auto'"
          @input="handleTabChange"
        >
          <s-tab-item
            v-for="tabItem in orderStatusList"
            :id="tabItem.id"
            :key="tabItem.orderStatus"
            v-tap="{ id: '1-20-1-164', data: tabItem.saParam || { order_status: tabItem.orderStatus.toLocaleLowerCase() } }"
          >
            {{ language[tabItem.title] }}
          </s-tab-item>
        </s-tab>

        <!-- 礼品卡订单列表跳转 -->
        <router-link
          v-if="(is_have_gfcard_order == '1') && !status_type"
          v-tap="{ id: '1-20-1-165' }"
          :to="publicLangPath + '/user/orders/giftcardlist'"
          style="text-decoration: none"
        >
          <div class="order-list-flexbetween go-gift-card-list">
            <span>{{ language.SHEIN_KEY_PWA_16931 }} </span>
            <em> <sui_icon_more_right_16px
              size="16px"
              :is-rotate="publicLocalData.GB_cssRight"
            /> </em>
          </div>
        </router-link>

        <!-- 待发货提示 -->
        <WaitSendReturnNotice
          v-if="topNoticeTips && status_type == 4"
          class="wait-send-return"
          :language="language"
          :notice-title="topNoticeTips"
          da-expose-id="1-20-1-131"
          da-click-id="1-20-1-132"
        />

        <!-- 二手买卖入口 -->
        <a
          v-if="abtInfos.isShowOrderResellEntrance && !status_type"
          da-event-click="1-20-1-55"
          :href="exchangeLink"
          style="text-decoration: none"
        >
          <div class="order-list-flexbetween go-gift-card-list">
            <span>{{ language.SHEIN_KEY_PWA_21443 }} </span>
            <em> <sui_icon_more_right_16px
              size="16px"
              :is-rotate="publicLocalData.GB_cssRight"
            /> </em>
          </div>
        </a>

        <!-- 评论积分提示 -->
        <comment-reward v-if="reviewPromptTip" />

        <!-- 空列表 -->
        <empty-state
          v-if="orders.length == 0 && status.historyExtra"
          :configs="{
            content: `${language.SHEIN_KEY_PWA_15043} :-(`
          }"
        />

        <!-- 订单列表 -->
        <template v-else-if="status_type == 4">
          <order-return-item
            v-for="(order, index) in renderOrders"
            :key="`${order.billno}_${status_type}_${index}`"
            class="macc-order-list order-list-new"
            :data-datetime="order.addTime"
            :data-address="order.shippingaddr_info && order.shippingaddr_info.shipping_country_name"
            :data-payment_method="order.payment_method"
            :order="order"
            :index="index"
            @showCombinedReturnEvt="showCombinedReturnEvt"
          />
        </template>
        <template v-else>
          <order-list-item
            v-for="(order, index) in renderOrders"
            :key="`${order.billno}_${status_type}_${index}`"
            class="macc-order-list order-list-new"
            :data-datetime="order.addTime"
            :data-address="order.shippingaddr_info && order.shippingaddr_info.shipping_country_name"
            :data-payment_method="order.payment_method"
            :order="order"
            :index="index"
            :show-mark="markOrderShow(order)"
          >
            <template #default="{ payNowLabelList, repurchaseAnalysisData, orderBtnMore }">
              <list-item
                :language="language"
                :order="order"
                :index="index"
                :pay-now-label-list="payNowLabelList"
                @showCombinedStatus="showCombinedStatus"
              />
              <ClientOnly>
                <div class="list-bot not-fsp-element">
                  <div
                    v-if="order.isReadOnly != 1 && !order.isOldOrder"
                    :id="'list-btn-' + order.billno"
                    ref="listContent"
                    class="list-bot-btn"
                  >
                    <template v-for="name in opeButtonSort(order)">
                      <!-- one click pay -->
                      <s-button
                        v-if="name === 'one_click_pay'"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-58', data: { order_no: order.billno } }"
                        v-tap="{ id: '1-20-1-59', data: { order_no: order.billno, type: 'anchor' } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-58', data: { order_no: order.billno } })"
                        :type="['H56PX']"
                        class="one_click_pay-list order-list-bot__btn"
                        @click="toDetailRecommendEvt(order)"
                      >
                        {{ order.ocb.button_tip }}
                        <div class="free_tips">
                          <div class="free_text">
                            <i class="suiiconfont sui_icon_free_shipping_12px icon"></i>
                            {{ order.ocb.button_pop_up_tip }}
                          </div>
                        </div>
                      </s-button>

                      <!-- 编辑地址按钮 -->
                      <EditAddressBtnNew
                        v-if="'edit_address' == name"
                        :key="`oper_button_${name}`"
                        :language="language"
                        :order="order"
                        :newEditAddressOptions="newEditAddressOptions"
                        :editAddressNewConfigOptions="editAddressNewConfigOptionsEvt(order)"
                        :class="[ showEditAddressLink(order) ? 'edit-address-link' : '']"
                        @showEditAddressDialog="showEditAddressDialogEvt"
                      />

                      <!-- 催派 -->
                      <s-button
                        v-if="name == 'urge_delivery'"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-86', data: { order_no: order.billno } }"
                        v-tap="{ id: '1-20-1-87', data: { order_no: order.billno } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-86', data: { order_no: order.billno } })"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="showUrgeDelivery(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_26632 }}
                      </s-button>

                      <!-- 取消订单 -->
                      <s-button
                        v-if="'cancel_order' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-139', data: { order_id: order.billno } }"
                        v-tap="{ id: '1-20-1-138', data: { order_id: order.billno } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-139', data: { order_id: order.billno } })"
                        :type="['H56PX']"
                        :class="['order-list-bot__btn', { 'opacity-item': getCancelOrderDisabledBtn(order) }]"
                        @click="orderRefund(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_15498 }}
                      </s-button>

                      <s-button
                        v-if="name == 'urgent_pickup'"
                        :key="name"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="urgeReturnEvt(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_31578 }}
                      </s-button>

                      <!-- view invoices -->
                      <s-button
                        v-if="name === 'view_invoices'"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-29', data: { page_name: 'page_order_list' } }"
                        v-tap="{ id: '1-20-1-28', data: { page_name: 'page_order_list' } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-29', data: { page_name: 'page_order_list' } })"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="navToViewInvoices(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_18679 }}
                      </s-button>

                      <!-- pay now -->
                      <template v-if="'pay_now' == name">
                        <PayNowBtn 
                          v-if="showOrderListPromotionTest"
                          :key="`oper_button_pay_${name}`"
                          v-expose="{ id: '1-20-1-149', data: { order_id: order.billno, bussiness_tp: orderBusiModel(order), buy_tp: orderBuyTpInfo(order), inducement_type: orderInducementType(payNowLabelList) } }"
                          v-tap="{ id: '1-20-1-148', data: { order_id: order.billno, bussiness_tp: orderBusiModel(order), buy_tp: orderBuyTpInfo(order), inducement_type: orderInducementType(payNowLabelList) } }"
                          :expose-info="JSON.stringify({ id: '1-20-1-149', data: { order_id: order.billno, bussiness_tp: orderBusiModel(order), buy_tp: orderBuyTpInfo(order), inducement_type: orderInducementType(payNowLabelList) } })"
                          class="order-list-bot__btn"
                          :order="order"
                          :language="language"
                          :pay-now-label-list="payNowLabelList"
                          @click.stop="newPayNowOrVerifyNowEvt(order, { payType: order.enableBarcode ? 2 : 1 })"
                        >
                          {{ order.enableBarcode ? language.SHEIN_KEY_PWA_24198 : order.payButtonText || language.SHEIN_KEY_PWA_15655 }}
                        </PayNowBtn>
                        <template v-else>
                          <s-button
                            v-if="order.enableBarcode"
                            :key="`oper_button_pay_${name}`"
                            :type="['H56PX']"
                            class="j-pay-now-list order-list-bot__btn"
                            @click.stop="newPayNowOrVerifyNowEvt(order, { payType: 2 })"
                          >
                            {{ language.SHEIN_KEY_PWA_24198 }}
                            <div
                              v-if="showExpireCountDown(order)"
                              class="j-pay-now-warp"
                            >
                              <order-expire-time-down :order="order" />
                            </div>
                          </s-button>
                          <s-button
                            v-else
                            :key="`oper_button_pay_${name}_noenableBarcode`"
                            v-expose="{ id: '1-20-1-149', data: { order_id: order.billno, bussiness_tp: orderBusiModel(order), buy_tp: orderBuyTpInfo(order), inducement_type: orderInducementType(payNowLabelList) } }"
                            v-tap="{ id: '1-20-1-148', data: { order_id: order.billno, bussiness_tp: orderBusiModel(order), buy_tp: orderBuyTpInfo(order), inducement_type: orderInducementType(payNowLabelList) } }"
                            :expose-info="JSON.stringify({ id: '1-20-1-149', data: { order_id: order.billno, bussiness_tp: orderBusiModel(order), buy_tp: orderBuyTpInfo(order), inducement_type: orderInducementType(payNowLabelList) } })"
                            :type="['H56PX']"
                            class="j-pay-now-list order-list-bot__btn"
                            @click.stop="newPayNowOrVerifyNowEvt(order, { payType: 1 })"
                          >
                            {{ order.payButtonText || language.SHEIN_KEY_PWA_15655 }}
                            <div
                              v-if="showExpireCountDown(order)"
                              class="j-pay-now-warp"
                            >
                              <order-expire-time-down :order="order" />
                            </div>
                          </s-button>
                          <!-- Other Payment Method -->
                          <s-button
                            v-if="order.enableBarcode"
                            :key="`oper_button_other_${name}`"
                            :type="['H56PX']"
                            class="order-list-bot__btn"
                            @click.stop="newPayNowOrVerifyNowEvt(order, { payType: 1 })"
                          >
                            {{ language.SHEIN_KEY_PWA_24378 }}
                          </s-button>
                        </template>
                      </template>

                      <!-- in站不能在ar站短信验证 -->
                      <s-button
                        v-if="'verify_now' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-141' }"
                        v-tap="{ id: '1-20-1-140' }"
                        :expose-info="JSON.stringify({ id: '1-20-1-141' })"
                        :type="['H56PX']"
                        class="j-pay-now-list order-list-bot__btn"
                        @click.stop="newPayNowOrVerifyNowEvt(order, { payType: 2 })"
                      >
                        {{ language.SHEIN_KEY_PWA_15370 }}
                        <div
                          v-if="showExpireCountDown(order)"
                          class="j-pay-now-warp"
                        >
                          <order-expire-time-down :order="order" />
                        </div>
                      </s-button>

                      <s-button
                        v-if="'repurchase' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-143', data: repurchaseAnalysisData }"
                        :expose-info="JSON.stringify({ id: '1-20-1-143', data: repurchaseAnalysisData })"
                        :type="['H56PX']"
                        class="order-list-bot__btn repurchase-btn"
                        @click="toRepurchaseEvt(order, repurchaseAnalysisData)"
                      >
                        <RepurchaseLabel 
                          v-if="showNewRepurchase && order.repurchase_lure_info && order.repurchase_lure_info.tip"
                          :locals="publicLocalData"
                          :repurchase-info="order.repurchase_lure_info"
                        />
                        {{ language.SHEIN_KEY_PWA_16211 }}
                      </s-button>

                      <!-- 物流轨迹 -->
                      <s-button
                        v-if="'track' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-137', data: { order_id: order.billno, order_status: order.orderStatus } }"
                        v-tap="{ id: '1-20-1-136', data: { order_id: order.billno, order_status: order.orderStatus } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-137', data: { order_id: order.billno, order_status: order.orderStatus } })"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="handelTrackBtnClick(order)"
                      >
                        <span v-html="language.SHEIN_KEY_PWA_15232"></span>
                      </s-button>

                      <s-button
                        v-if="'exchange' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-97', data: { order_no: order.billno, style: getSelfExchangeStatus(order) ? 'gray' : 'normal' } }"
                        v-tap="{ id: '1-20-1-98', data: { order_no: order.billno, style: getSelfExchangeStatus(order) ? 'gray' : 'normal' } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-97', data: { order_no: order.billno, style: getSelfExchangeStatus(order) ? 'gray' : 'normal' } })"
                        :class="['order-list-bot__btn', { 'opacity-item': getSelfExchangeStatus(order) }]"
                        :type="['default', 'H56PX']"
                        @click="handleExchangeOperation(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_29069 }}
                      </s-button>

                      <s-button
                        v-if="'revoke' == name"
                        :key="`oper_button_${name}`"
                        :type="['H56PX']"
                        :class="['order-list-bot__btn', { 'opacity-item': getCancelDisabledBtn(order) }]"
                        @click="toRevokeOrder(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_15498 }}
                      </s-button>


                      <s-button
                        v-if="'review' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-151' }"
                        v-tap="{ id: '1-20-1-150' }"
                        :expose-info="JSON.stringify({ id: '1-20-1-151' })"
                        :type="['H56PX']"
                        class="order-review-list order-list-bot__btn"
                        @click="navToReview(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_15162 }}
                        <div
                          v-if="showPointsTipEvt(order)"
                          class="review_tips"
                        >
                          <div class="review_text">
                            <i class="suiiconfont sui_icon_checkin_integral_32px icon"></i>
                            {{ language.SHEIN_KEY_PWA_16291 }}
                          </div>
                        </div>
                      </s-button>

                      <!-- upload report -->
                      <s-button
                        v-if="'upload_report' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-26', data: { status: order.isCanUpload == 2 ? '1' : '2' } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-26', data: { status: order.isCanUpload == 2 ? '1' : '2' } })"
                        :type="['H56PX']"
                        :class="['order-list-bot__btn', { 'opacity-item': order.isCanUpload == 2 } ]"
                        @click="toEditReport(order, 'button')"
                      >
                        {{ language.SHEIN_KEY_PWA_16228 }}
                      </s-button>

                      <s-button
                        v-if="'my_review' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-145' }"
                        v-tap="{ id: '1-20-1-144' }"
                        :expose-info="JSON.stringify({ id: '1-20-1-145' })"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="navToMyReview(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_16290 }}
                      </s-button>

                      <!--自主退货-->
                      <s-button
                        v-if="'return_item' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-173', data: { position: getReturnItemBtnInfo(orderBtnMore) } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-173', data: { position: getReturnItemBtnInfo(orderBtnMore) } })"
                        name="returnItem"
                        class="j-return-btn ga-return-item order-list-bot__btn"
                        :class="{'opacity-item': order.returnButtonIsGray == 1}"
                        :type="['H56PX']"
                        @click="handleReturnItemEvt(order, orderBtnMore)"
                      >
                        {{ language.SHEIN_KEY_PWA_33481 }}
                      </s-button>

                      <!--自主标记功能-->
                      <s-button
                        v-if="'resume_shipment' == name"
                        :key="name"
                        v-expose="{ id: '1-20-1-178', data: { order_id: order.billno } }"
                        v-tap="{ id: '1-20-1-179', data: { order_id: order.billno } }"
                        class="order-list-bot__btn resume-shipment-btn"
                        :type="['H56PX']"
                        @click="resumeShipment(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_34358 }}
                      </s-button>

                      <!-- confirm delivery -->
                      <s-button
                        v-if="'confirm_delivery' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-135', data: { order_id: order.billno } }"
                        v-tap="{ id: '1-20-1-134', data: { order_id: order.billno } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-135', data: { order_id: order.billno } })"
                        :type="['H56PX']"
                        :class="['order-list-bot__btn', { 'opacity-item': !!order.receive_msg } ]"
                        @click="confirmDeliverAlterFun(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_14854 }}
                      </s-button>

                      <s-button
                        v-if="'urge_shipment' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-84', data: { order_no: order.billno } }"
                        v-tap="{ id: '1-20-1-85', data: { order_no: order.billno } }"
                        :expose-info="JSON.stringify({ id: '1-20-1-84', data: { order_no: order.billno } })"
                        :type="['default', 'H56PX']"
                        class="order-list-bot__btn"
                        @click="urgeshipment(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_26377 }}
                      </s-button>
                    </template>

                    <div
                      v-if="order.order_type != 5 && orderBtnMore.isShowBtnMore"
                      class="my-reviewed-comment"
                    >
                      <div
                        :ref="`${order.billno}-more-list`"
                        class="btn-more-list"
                        style="display: none"
                      >
                        <p
                          v-for="(item, childIndex) in orderBtnMore.btnMoreList"
                          :key="childIndex"
                          v-expose="getBtnMoreAnalysis(item)"
                          class="btn-more"
                          :class="item.addClass"
                          @click="handleBtnMoreItem(item)"
                          v-html="item.text"
                        >
                        </p>
                      </div>
                      <a
                        v-tap="{ id: '1-20-1-157', data: { order_status: order.orderStatus } }"
                        @click="handleMyReviewShow(`${order.billno}-more-list`)"
                      >
                        <span 
                          v-if="abtInfos.moreStyle === 'text' && publicLocalData.lang != 'euqs'"
                          class="more-text"
                        >
                          {{ language.SHEIN_KEY_PWA_23768 }}
                        </span>
                        <template v-else>
                          <span class="point"></span>
                          <span class="point"></span>
                          <span class="point"></span>
                        </template>
                      </a>
                    </div>
                  </div>
                </div>
              </ClientOnly>
            </template>
          </order-list-item>
        </template>

        <div
          v-if="
            status.historyStatus &&
              orders.length > 0 &&
              !orderSearchMsg.isSearchList &&
              status.showHistory
          "
          class="order-list-text-center order-list-history"
        >
          <a
            href="javascript:;"
            @click="historyListFun(2)"
          >
            <span v-html="language.SHEIN_KEY_PWA_16184"></span>
            <i class="iconfont icon-down"></i>
          </a>
        </div>
        <div
          v-if="!status.loading && orders.length == 0 && !status.historyExtra"
          style="background: #FFF"
        >
          <!-- 空状态组件 -->
          <empty-state
            :configs="emptyConfig"
            @on-button-click="historyListFun(1)"
          />
        </div>

        <FindOrder v-if="showFindOrderFlag" />

        <!-- 客户端渲染 -->
        <ClientOnly>
          <!-- tab切换loading -->
          <LazyMount mount-prop-name="show">
            <s-loading
              type="curpage"
              :show="status.loading"
              :mask-transparent="false"
              :container-cover-style="{ position: 'fixed', height: '100%', width: '100%', top: '1.17rem' }"
            />
          </LazyMount>

          <!-- 确认收货触发发积分场景事前校验 -->
          <LazyMount>
            <commonSelectiveTipsDialog
              class="confirm-deliver-alter-modal"
              :visible.sync="orderDeliveryPointCheckTips.showModal"
              :title="orderDeliveryPointCheckTips.text"
              :primaryText="language.SHEIN_KEY_PWA_15252"
              :secondaryText="language.SHEIN_KEY_PWA_15253"
              :close-on-click-modal="false"
              hor
              reverse
              :num="2"
              @primary="confirmDeliver(orderInfo)"
              @secondary="cancelPreCheckConfirmDeliver"
            />
          </LazyMount>
          
          <!-- codOrderIsNotSupportReceipt alter -->
          <LazyMount>
            <commonTipsDialog
              :visible.sync="codOrderIsNotSupportReceipt"
              :title="codOrderIsNotSupportReceiptTip"
              :btnText="language.SHEIN_KEY_PWA_15146"
              :btnType="['primary', 'H80PX']"
              :close-on-click-modal="false"
            />
          </LazyMount>

          <!-- 地址 Start -->
          <!-- 接口成功与错误弹窗 -->
          <LazyMount>
            <editAddressTipsDialog
              :visible.sync="orderAddress.show"
              :language="language"
              :type="orderAddress.type"
              :text="orderAddress.text"
            />
          </LazyMount>
          <!-- 地址组件 -->
          <address-vue
            ref="addressVue"
            :show="addressShow"
            :page-name="'orderlist'"
            :page-type="editAddressType"
            :extra="extraAddress"
            :disabled="disabledAddress"
            @edit="addressEdit"
            @cancel="addressCancel"
            @error="updateError"
          />
          <!-- 店铺地址组件 -->
          <store-address-vue
            v-if="isShopAddress"
            ref="storeAddress"
            :page-type="'order'"
            @save="newSaveStoreAddress"
            @cancel="storeAddressCancel"
          />
          <retry-address 
            ref="retryAddress"
            :language="language"
            @newAddressShippingEdit="newAddressShippingEdit"
            @retryConfirm="retryConfirm"
            @postStroeAddressData="postStroeAddressData"
          />
          <!-- 地址 End -->

          <!-- 接入推荐组件 -->
          <Recommend v-if="noMoreData" />
        </ClientOnly>
      </div>
    </div>

    <!-- 客户端渲染 -->
    <ClientOnly>
      <!-- 我的包裹悬浮球 -->
      <MyPackageEntrance
        v-if="isShowPackage == '1'"
        ref="myPackageEntranceRef"
        v-expose="{ id: '1-20-1-100' }"
        v-tap="{ id: '1-20-1-101' }"
      />
      
      <LazyMount mount-prop-name="show">
        <s-loading
          type="curpage"
          :mask-transparent="true"
          :show="status.msgLoading"
        />
      </LazyMount>

      <LazyMount>
        <commonSelectiveTipsDialog
          :visible.sync="refundNotCodMask"
          :title="language.SHEIN_KEY_PWA_15438"
          :primaryText="language.SHEIN_KEY_PWA_15312"
          :secondaryText="language.SHEIN_KEY_PWA_16092"
          :is-loading="refundNotCodMaskLoading"
          @primary="orderRefundMask"
          @secondary="refundNotCodMask = false"
          @close="refundNotCodMask = false"
        />
      </LazyMount>

      <!-- 信息提示 -->
      <LazyMount>
        <msgBoxDialog
          :visible.sync="status.msg"
          :type="msgBox.type"
          :info="msgBox.info"
        />
      </LazyMount>

      <LazyMount>
        <commonTipsDialog
          class="confirm-deliver-alter-modal"
          :visible.sync="confirmDeliverMessage"
          append-to-body
          :showClose="true"
          :title="confirmDeliverMessageText"
          :btnType="['primary', 'H80PX']"
          :btnText="[1, 3].includes(confirmDeliverMessageType) ? language.SHEIN_KEY_PWA_17939 : language.SHEIN_KEY_PWA_15146"
          @ok="confirmDeliverMessageEvt"
        />
      </LazyMount>

      <!-- 支付方式 -->
      <PaymentOptionsDrawer
        ref="paymentOptionsDrawerRef"
        :visible="pageStatus.showPaymentsDrawer"
        :module-name="moduleName"
        :beforeStartPay="asyncUpdateOrder"
        :createPayment="handleCreatePayment"
        :isDirectPay="true"
        @select-payment="handleSelectPaymentInfo"
        @confirm-payment="handleConfirmPayNow"
        @pay-now="handleConfirmPayNow"
        @init-select-payment="handleInitSelectPayment"
        @select-payment-change="handleSelectPaymentChange"
        @close="handleCloseDrawer"
        @manual-close="handleManualCloseDrawer"
      />

      <!-- 过期弹窗 -->
      <LazyMount mount-prop-name="show">
        <expired-modal
          :show="expiredModalConfig.show"
          :language="language"
          @toggle="toggleOrderExpiredModal"
        />
      </LazyMount>

      <!-- 疫情影响提示  -->
      <LazyMount>
        <commonTipsDialog
          :visible.sync="isShowReturnUnavailableModal"
          :title="!IS_RW ? language.SHEIN_KEY_PWA_17097 : language.SHEIN_KEY_PWA_17113"
          :btnText="language.SHEIN_KEY_PWA_15312"
          append-to-body
          is-title-left
          @close-from-icon="isShowReturnUnavailableModal = false"
        />
      </LazyMount>

      <!-- abtest关闭功能提示 -->
      <LazyMount>
        <commonTipsDialog
          :visible.sync="unavailableModal"
          :title="language.SHEIN_KEY_PWA_17115"
          :btnText="language.SHEIN_KEY_PWA_15312"
          append-to-body
          is-title-left
          @close-from-icon="toggleUnavailableModal"
        />
      </LazyMount>

      <!-- 回购确认弹窗 -->
      <repurchase-confirm-dialog
        :billno="repurchaseOrderBillno"
        :language="language"
        :show="repurchaseModel"
        @handleSave="handleRepurchase"
      />
      <!-- 添加购物车成功提示 -->
      <LazyMount>
        <AddCartSuccessDialog
          :visible.sync="addCartStatus.showDrawer"
          :language="language"
          :info="addCartStatus.cartInfo"
        />
      </LazyMount>
      <!-- 成功提示弹窗 -->
      <LazyMount mount-prop-name="show">
        <successPanel
          :show.sync="successPanelData.show"
          :billno="successPanelData.billno"
          from="orderList"
        />
      </LazyMount>

      <!-- 判断合单状态是否为混发 -->
      <LazyMount>
        <combinedOrderDialog
          :visible.sync="combinedOrderModal.show"
          :tips="combinedOrderModal.tips"
          :mergeBuyBillno="combinedOrderModal.merge_buy_billno"
          :language="language"
          @to-detail="navToDetail(combinedOrderModal.billno)"
        />
      </LazyMount>

      <!-- cod取消商品时的提示 -->
      <LazyMount>
        <cancel-item-tip
          :visible.sync="codCancelItemTipVisible"
          :language="language"
          @confirm="handleShowCodCancelItemSelect"
          @cancel="codCancelItemTipVisible = false"
        />
      </LazyMount>

      <!-- 多主体站点跳转 -->
      <orderCheckJumpMask
        ref="orderCheckJumpMask"
        :type="'list'"
        :language="language"
      />

      <!-- 已支付未发货cod订单单件退 Start -->
      <cancel-item-comp v-if="isOrderList" />
      <LazyMount>
        <commonTipsDialog
          :content="timeTips"
          isContentCenter
          :btnText="language.SHEIN_KEY_PWA_15146"
          :btnType="['primary', 'H72PX']"
          :visible.sync="isShowTimeTipsDialog"
        />
      </LazyMount>
      <LazyMount>
        <return-item-tip
          :visible.sync="showReturnItemTips.show"
          :title="showReturnItemTips.text"
          :type="showReturnItemTips.type"
          :language="language"
          :primaryText="language.SHEIN_KEY_PWA_24596"
          :secondaryText="language.SHEIN_KEY_PWA_24597"
          @ok="handleReturnItemTipsOk"
          @no="showReturnItemTips.show = false"
        />
      </LazyMount>
      <LazyMount>
        <commonTipsDialog
          :content="showFrontConditionText"
          :btnText="language.SHEIN_KEY_PWA_15146"
          :visible.sync="showFrontConditionDialog"
        />
      </LazyMount>
      <LazyMount>
        <commonTipsDialog
          :content="cancelOrderTipsText"
          :btnText="language.SHEIN_KEY_PWA_15146"
          :visible.sync="cancelOrderTips"
        />
      </LazyMount>
      <LazyMount>
        <commonTipsDialog
          :content="showBlackTips.text"
          :btnText="language.SHEIN_KEY_PWA_15312"
          :visible.sync="showBlackTips.show"
        />
      </LazyMount>
      <LazyMount>
        <commonTipsDialog
          :title="urgeshipmentText.title"
          :content="urgeshipmentText.content"
          :btnText="language.SHEIN_KEY_PWA_26382"
          :show-close="true"
          :visible.sync="isShowUrgeShipmentDialog"
        />
      </LazyMount>
      <!-- END -->

      <!-- 确认收货新提示 -->
      <LazyMount mount-prop-name="show">
        <confirmDeliveryTips
          :show="showConfirmDeliveryTips"
          :language="language"
          :confirmDeliveryGoodsInfo="confirmDeliveryGoodsInfo"
          @cancelConfirmDeliver="cancleConfirmOrder"
          @preCheckPointByConfirmOrder="preCheckPointByConfirmOrder"
        />
      </LazyMount>

      <!-- 卡bin优惠文章展示 Start -->
      <LazyMount>
        <CardBinDiscountArticle :visible.sync="visibleCardBinDiscountArticle" />
      </LazyMount>
      <LazyMount mount-prop-name="showConfirmDialog">
        <CustomerConfirmAddress
          :showConfirmDialog.sync="showConfirmDialog"
          :orderData="orderInfo"
          :language="language"
        />
      </LazyMount>
      <!-- End -->

      <!-- 批量修改地址确认弹窗 -->
      <LazyMount>
        <edit-address-confirm-dialog
          :visible.sync="showEditAddressConfirmDialog"
          :language="language"
          :batch-modify-address="batchModifyAddressData"
          :billno="orderInfo.billno"
          @confirm="onConfirmModifyAddress"
        />
      </LazyMount>

      <!-- 催派业务 -->
      <UrgeDeliveryModal
        ref="urgeDeliveryModalRef"
        page-from="order_list"
        :order-info="orderInfo"
        :language="language"
      />

      <!-- 订单不可换货提示 -->
      <LazyMount>
        <commonTipsDialog
          :content="disableExchangeTips"
          :btnText="language.SHEIN_KEY_PWA_26382"
          :btnType="['primary', 'H72PX']"
          isContentCenter
          :visible.sync="showExchangeTipsPop"
        />
      </LazyMount>

      <!-- 留评风控提示 -->
      <LazyMount>
        <RiskCommentDialog
          :visible.sync="showRiskCommentTips.show"
          :language="language"
          :riskCommentTipsInfo="showRiskCommentTips.info"
          @close-risk-comment-tips="closeRiskCommentTips"
        />
      </LazyMount>

      <!-- 地址编辑成功提示 -->
      <LazyMount>
        <commonTipsDialog
          :visible.sync="showBatchAddressMessage"
          :append-to-body="true"
          :close-on-click-modal="false"
          :show-close="false"
          :content="batchAddressMessage"
          :btn-type="['primary', 'H56PX']"
          :btn-text="language.SHEIN_KEY_PWA_15312"
          @ok="closeBatchAddressDialog"
        />
      </LazyMount>

      <UrgentPickUp
        ref="urgentPickUp"
        :language="language"
      />
      <!-- 订单不可换货提示 -->
      <LazyMount>
        <commonTipsDialog
          :content="disableExchangeTips"
          :btnText="language.SHEIN_KEY_PWA_26382"
          :btnType="['primary', 'H72PX']"
          isContentCenter
          :visible.sync="showExchangeTipsPop"
        />
      </LazyMount>

      <!-- 留评风控提示 -->
      <LazyMount>
        <RiskCommentDialog
          :visible.sync="showRiskCommentTips.show"
          :language="language"
          :riskCommentTipsInfo="showRiskCommentTips.info"
          @close-risk-comment-tips="closeRiskCommentTips"
        />
      </LazyMount>
      
      <!-- 退货二次确认 -->
      <LazyMount>
        <ReturnConfirmPop
          :visible="isSignPop.show"
          :language="language"
          :order-info="orderInfo"
          :sign-info="isSignPop.info"
          :from="'order_list'"
          @close-confirm-pop="() => { isSignPop.show = false }"
          @confirm-return="confirmReturnEvt"
        />
      </LazyMount>

      <!-- 退货时效过期 -->
      <LazyMount>
        <ReturnExpirePop
          :visible="isExpirePop.show"
          :language="language"
          :expire-info="isExpirePop.info"
          @close-expire-pop="() => { isExpirePop.show = false }"
        />
      </LazyMount>
      
      <LazyMount>
        <commonTipsDialog
          :content="showCombinedReturnText"
          isContentCenter
          :btnText="language.SHEIN_KEY_PWA_15312"
          :btnType="['primary']"
          :show-close="false"
          :visible.sync="showCombinedReturn"
        />
      </LazyMount>
      <!-- 自主标记确认弹窗 -->
      <LazyMount>
        <ResumeShipmentDialog
          :visible.sync="showResumeShipmentDialog"
          :language="language"
          :async-callbacks="asyncCallbacks"
          :info="resumeShipmentInfo"
          :order-info="orderInfo"
        />
      </LazyMount>

      <OrderMaskDialog />

      <!-- cod挽留弹窗 -->
      <BackTips
        v-if="selectedPaymentInfo?.payment_code === 'cod'"
        ref="paymentBackTipsRef"
        @back-tips-mounted="handleBackTipsMounted"
      />

      <CreateOrderBinDialog
        :language="language"
        :beforeStartPay="asyncUpdateOrder"
        :tempCreateOrderBinDialog="createOrderBinDialog"
        @pay-now="handleConfirmPayNow"
        @update-create-order-bin-dialog="updateCreateOrderBinDialog"
      />
    </ClientOnly>
  </div>
</template>

<script>
// 组件
import components from './components'
// 组件 END

// 埋点
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })
daEventCenter.addSubscriber({ modulecode: '2-30' })
let SaPageInfo = {
  page_id: 6,
  page_name: 'page_order_list',
  page_param: { order_count: '' },
  start_time: Date.now()
}
// 埋点 END

// 数据源
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import orderLogic from 'public/src/pages/common/orderLogic'
import orderListStoreModule from '../../../../product_app/store/modules/user/order_list_bff'
import orderDetailStoreModule from '../../../../product_app/store/modules/user/order_detail'
import cancelItemStoreModule from '../../../../product_app/store/modules/user/order-cancel-item'
/** mixin */
import addressMixin from '../common/addressMixin'
import returnItemMixin from '../common/returnItemMixin'
import paymentMixin from 'public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/paymentMixin.js'
import mixin from '../common/mixin'
// 数据源 END

// 接口请求
import { getBffListData, getBffDataBySearchKeyword } from 'public/src/pages/user/child_pages/orders_bff/list/getBaseData'
import {
  get_order_info_ser,
  submitUrgeShipInfoSer,
  getReviewRiskSer,
  getHistoryListSer,
  isSupportDistributionCountrySer,
  checkOrderIsSupportReceiptSer,
  confirmDeliveryTipsSer,
  confirmDeliverySer,
  confirmDeliveryOfNewSer,
  orderReturnableSer,
  switchPaymentSer
} from './service.js'
// 接口请求 END

import { hasRobotEntrance } from 'public/src/pages/common/robotEntrance'
import { markPoint } from 'public/src/services/mark/index.js'
import { robotLinkCommon } from 'public/src/pages/common/utils/index.js'
import orderFactory from '../common/orderFactory.js'
import { getOrderButtonSorts } from './../utils'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'

import {
  getQueryString,
  template,
  setLocalStorage
} from '@shein/common-function'
import { userOrderDetailRouteComponent } from 'public/src/pages/product_app/router/user-order-router.js'

let initSsrDataPromise = null

export default {
  name: 'UserOrdersList',
  components,
  directives: { tap, expose },
  mixins: [
    mixin,
    addressMixin,
    returnItemMixin,
    paymentMixin({ storeNameSpace: 'orderList' }),
  ],
  async asyncData({ store, context, route, from }) {
    if (!store.state['orderList']) {
      store.registerModule('orderList', orderListStoreModule)
    }
    if (!store.state['orderDetail']) {
      store.registerModule('orderDetail', orderDetailStoreModule)
    }
    if (!store.state['cancelItemModule']) {
      store.registerModule('cancelItemModule', cancelItemStoreModule)
    }
    // 从个人中心跳转
    if (from?.name === 'UserIndex') {
      store.commit('orderList/assignOrderListState', {
        orderSearchMsg: {
          content: '',
          show: false,
          isSearchList: false,
          page: 1
        }
      })
    }
    // reset
    store.commit('orderList/assignOrderListState', {
      orders: [],
      page: 1,
      status: {
        historyStatus: false,
        historyExtra: false,
        historyShow: false
      }
    })
    if (!store.state?.orderList?.orderSearchMsg?.isSearchList) {
      store.commit('orderList/assignOrderListState', {
        noMoreData: false
      })
    }
    // init status_type
    const tabMap = ['All', 'Unpaid', 'Processing', 'Shipped', 'Review', 'Returns']
    const tabParams = route?.params?.tab || ''
    // query
    const queryStatusType = Number(getQueryString({ key: 'status_type' }) || context?.status_type || 0)
    // params
    const paramStatueType = tabMap.indexOf(tabParams) > -1 ? tabMap.indexOf(tabParams) : 0
    // store sort
    const statusType = store.state.root.statusType || paramStatueType || queryStatusType || 0
    store.commit('root/assignRootState', {
      statusType
    })
    store.commit('root/updateIsErrorGuideOn', true)
    store.commit('orderList/assignOrderListState', {
      status_type: statusType
    })
    initSsrDataPromise = store.dispatch('orderList/initSsrData', {
      context,
      statusType,
      route,
      from
    })
    if (typeof window !== 'undefined') {
      try {
        sessionStorage.setItem('return_page_from', 'list')
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    }
  },
  data () {
    return {
      TRANSPORT_TYPE_SHOP,
      module_name: 'OrderList', // 模块名称
      moduleName: 'orderList',
      robotShow: false,
      cancelOrderTips: false,
      cancelOrderTipsText: '',
      showDeleteHistoryPopover: false,
      refundNotCodMask: false,
      refundNotCodMaskLoading: false,
      codCancelItemTipVisible: false,
      isShowTimeTipsDialog: false,
      showFrontConditionDialog: false,
      isShowUrgeShipmentDialog: false,
      showRelationDialog: false,
      showConfirmDeliveryTips: false,
      visibleCardBinDiscountArticle: false,
      showConfirmDialog: false,
      showExchangeTipsPop: false,
      codOrderIsNotSupportReceipt: false,
      showFindOrderFlag: true,
      orderInfo: {},
      inReturnItem: { billno: '', mask: false },
      msgBox: { info: '', type: '' },
      showBlackTips: { show: false, text: '' },
      showRiskCommentTips: { show: false, info: {} },
      showReturnItemTips: { show: false, text: '', type: 0 },
      urgeshipmentText: { content: '', title: '' },
      confirmDeliverMessage: false,
      confirmDeliverMessageType: '',
      confirmDeliverMessageText: '',
      orderDeliverTips: '',
      repurchaseOrderBillno: '',
      repurchaseModel: { show: false },
      successPanelData: { show: false, billno: '' },
      timeTips: '',
      showFrontConditionText: '',
      combinedOrderModal: {
        show: false,
        billno: '',
        merge_buy_billno: '',
        tips: ''
      },
      confirmDeliveryGoodsInfo: {},
      preCheckPointByConfirmInfo: {},
      orderDeliveryPointCheckTips: {
        showModal: false,
        text: ''
      },
      disableExchangeTips: '',
      riskCommentArr: [],
      codOrderIsNotSupportReceiptTip: '',
      loadingMore: false,
      pageold: 1,
      hasMounted: false,
      isSignPop: {
        show: false,
        info: {}
      },
      isExpirePop: {
        show: false,
        info: {}
      },
      addCartStatus: {
        showDrawer: false,
        cartInfo: {},
      },
      showCombinedReturnText: '',
      showCombinedReturn: false,
      asyncCallbacks: [],
      resumeShipmentInfo: {},
      showResumeShipmentDialog: false,
      returnItemBtnPosition: '',
      expiredModalConfig: {
        show: false,
        order: null
      },
      isShowCreateOrderBinDialog: false,
    }
  },
  computed: {
    ...mapState(['unReadChatNum']),
    ...mapState('root', ['statusType']),
    ...mapState('orderList', [
      'orderSearchMsg', 'status_type', 'pageLoading',
      'specialOutgoingOrderList', 'abnormalOrderList', 'abnormalOrderListType',
      'orders', 'page', 'status',
      'noMoreData', 'isSsr', 'publicLocalData',

      // bff
      'apolloInfo',
      'abtInfos',
      'relationAccount',
      'language',
      'orderStatusList',
      'is_have_gfcard_order',
      'reviewPromptTip',
      'isShowPackage',
      'orderListNotice',
      'isDemotion',
      'unionData',
      'topNoticeTips',
      'historyOrders',
      'pageStatus'
    ]),
    ...mapGetters('orderList', ['ordersStatus']),
    ...mapGetters('orderDetail', ['getCodOrderFrom']),
    markOrderParams() {
      return this.abtInfos.markOrderParams || {}
    },
    // 订单列表回购显示利诱
    showNewRepurchase() {
      const { PromosPosition = '' } = this.abtInfos.orderRepurchaseParam || {}
      return Boolean(PromosPosition?.includes('list'))
    },
    // 订单列表回购成功显示新的成功页
    showNewRepurchaseSuccess() {
      const { NewRepurchase } = this.abtInfos.orderRepurchaseParam || {}
      return NewRepurchase === 'on'
    },
    /** bff按钮相关新逻辑 start **/
    /** bff按钮相关新逻辑 end **/
    renderOrders () {
      if (!this.orders?.length) {
        return []
      }
      return this.hasMounted ? this.orders : this.orders.slice(0, 3)
    },
    isShopAddress () {
      let { shippingaddr_info = {} } = this.orderInfo || {}
      let { is_shop_transit, subsidiary = {} } = shippingaddr_info || {}

      return is_shop_transit == '1' || TRANSPORT_TYPE_SHOP.includes(subsidiary?.transport_type)
    },
    popoverStyle() {
      return this.publicLocalData.GB_cssRight ? { left: '0.32rem', right: 'auto', width: '120px' } : { right: '0.32rem', left: 'auto', width: '120px' }
    },
    publicLangPath () {
      return this.publicLocalData?.langPath || ''
    },
    IS_RW () {
      return this.publicLocalData?.WEB_CLIENT == 'romwe'
    },
    // 二手地址链接
    exchangeLink() {
      const { SiteUID, appLanguage } = this.publicLocalData || {}
      return (
        this.apolloInfo?.RESALE_SITE_LINK?.filter?.(item => item.siteUid === SiteUID)?.[0]?.url ||
        `${this.publicLangPath}/exchange/${appLanguage}/resale-list`
      )
    },
    showRefundNew() {
      return this.abtInfos?.abTestConfig?.['refundNew'] !== 'off'
    },
    isOrderList() {
      return this.$route.name === 'UserOrdersList'
    },
    applePayCodeList () {
      return this.apolloInfo?.APPLE_PAY_CODE_LIST || []
    },
    showUrgeDeliveryBtn() {
      return order => {
        return order.orderStatus == 10 && this.abtInfos.isShowUrgeDeliveryAbt && order.isDisplayDelivery
      }
    },
    newEditAddressOptions () {
      return {
        batchModifyAddress: this.abtInfos.batchModifyAddressAbt,
        pageSource: 'OrderList',
        btnSource: 'Edit'
      }
    },
    // 订单列表开启利诱点信息实验
    showOrderListPromotionTest() {
      return ['on1', 'on2'].includes(this.abtInfos?.unpaidOrderTestAbt?.OrderlistPromotionTest)
    },
    emptyConfig() {
      let iconName = this.orderSearchMsg.isSearchList ? 'sui_icon_empty_75px_1' : 'sui_icon_empty_list_75px_color'
      let title = ''
      let content = ''
      let buttonText = ''
      let buttonNumber = 0

      if (this.orderSearchMsg.isSearchList) {
        content = `${this.language.SHEIN_KEY_PWA_16115} :-(`
      } else if (this.status_type || !this.status.showHistory) {
        content = `${this.language.SHEIN_KEY_PWA_15043} :-(`
      }
    
      if (this.isDemotion) {
        title = content
        content = this.language.SHEIN_KEY_PWA_17808
      }

      if (!this.orderSearchMsg.isSearchList && !this.status_type && this.status.showHistory) {
        buttonNumber = 1
        buttonText = this.language?.SHEIN_KEY_PWA_16184?.toUpperCase?.()
      }

      return {
        title,
        content,
        buttonText,
        iconName,
        buttonNumber
      }
    },
    visibleCreateOrderBinDialog() {
      return this?.createOrderBinDialog?.show
    }
  },
  mounted () {
    window.orderListDom = this
    setTimeout(() => {
      userOrderDetailRouteComponent()
    }, 200)
    this.getCsChatMsgNumFirst()
    this.handleMethod()
  },
  beforeRouteEnter(to, from, next) {
    if (typeof window === 'undefined') {
      return next(() => {})
    }
    window.SaPageInfo = { ...SaPageInfo, page_param: { ...(SaPageInfo.page_param || {}), order_count: '' } }
    appEventCenter.$emit('pageOnload')
    next(vm => {
      hasRobotEntrance('orderListPage', status => {
        vm.robotShow = status
      })
      vm.setStatusLoading(false)
      if (window.temproryHidePackagePop && vm.$refs.myPackageEntranceRef) {
        vm.$refs.myPackageEntranceRef?.temporaryShow()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    window.SaPageInfo = SaPageInfo
    if (window.temproryHidePackagePop && this.$refs.myPackageEntranceRef) {
      this.$refs.myPackageEntranceRef?.temporaryHide()
    }
    next()
  },
  async activated() {
    // 只在客户端 再次进入时触发埋点
    this.hasMounted = true
    await Promise.resolve(initSsrDataPromise)
    this.$nextTick(() => {
      markPoint('setPageDataNextTick', 'setPageData')
    })
    const { order_count = '' } = SaPageInfo?.page_param || {}
    if (order_count) {
      window.SaPageInfo = {
        page_id: 6,
        page_name: 'page_order_list',
        page_param: { order_count: order_count },
        start_time: Date.now()
      }
      appEventCenter.$emit('pageOnload')
      $(window).scroll() // 触发曝光埋点
    }
    // 页面请求
    await this.getPageData()

    // 只在客户端
    if (this.abtInfos.isShowOrderResellEntrance) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-56'
      })
    }
    this.showDeleteHistoryPopoverHandle()
  },
  methods: {
    template,
    ...mapMutations('root', ['showMaskTips', 'assignRootState', 'changeUserRootSLoadingStatus', 'changeHeaderNav', 'updateHasGuide']),
    ...mapMutations(['changeRootStatus']),
    ...mapActions(['getCsChatMsgNumFirst']),
    ...mapMutations('userIndex', ['setStoreState']),
    ...mapMutations('orderDetail', ['assignState']),
    ...mapMutations('cancelItemModule', ['assignCancelItemState']),
    ...mapMutations('orderList', ['assignLanguage', 'assignOrderListState', 'handleEditPayment', 'changePageStatus', 'updateByStatusOrderList', 'updateAllOrderList', 'updateSomeOrderInfo', 'updateHistoryOrderList']),
    toRecycle () {
      this.$router.push(this.publicLangPath + '/user/orders/recycle')
    },
    // 刷新单个订单信息
    async refreshOrderInfo(billno) {
      const requestBillno = billno || this.orderInfo.billno
      if (!requestBillno) return
      const res = await getBffDataBySearchKeyword({ page: 1, keyword: requestBillno })
      if (res?.order_list?.length) {
        this.updateSomeOrderInfo({ 
          orderInfo: res.order_list[0]
        })
      }
    },
    // 订单列表展示标记按钮
    markOrderShow(item = {}) {
      return item?.show_order_mark == 1
    },
    // 编辑订单地址后展示订单标记问询
    addresShowResumeShipment(res = {}) {
      const orderInfoList = res.info?.order_info_list || []
      const orderInfo = orderInfoList.find(i => i.billno === this.orderInfo.billno)
      const orderMarkList = orderInfo?.order_mark_list || []
      return (
        this.markOrderParams.orderaddress_show == 'on' && 
        orderMarkList.some(i => i.mark_type == 1 && i.mark_type_status == 1 && i.trouble_reason == '1')
      )
    },
    async resumeShipment(order = {}) {
      this.orderInfo = order
      const res = await this.resumeShipmentSleep({ scene: 'order_list' })
      if (res) {
        this.refreshOrderInfo()
      }
    },
    // 等待用户选择自主标记的结果
    resumeShipmentSleep(info = {}) {
      return new Promise(resolve => {
        this.resumeShipmentInfo = info
        this.showResumeShipmentDialog = true
        this.asyncCallbacks = [resolve]
      })
    },
    // 无网提示
    onlineChange(online) {
      if (online && this.orders?.length) {
        if (this.loadingMore) {
          this.loadingMore = false
        }
      }
    },
    /** 头部功能 Start */
    // 回退
    goBack() {
      this.setStatusLoading(false)
      markPoint('toNextPageClick', 'public')
      this.$router.push(this.publicLangPath + '/user/index')
    },
    // 搜索
    orderSearchOpen() {
      this.orderSearchCancel()
      this.assignOrderListState({
        orderSearchMsg: {
          show: !this.orderSearchMsg.show
        }
      })
    },
    orderSearchCancel() {
      const { content = '', isSearchList } = this.orderSearchMsg
      if (!content) {
        this.assignOrderListState({
          orderSearchMsg: {
            show: false
          }
        })
      }
      this.assignOrderListState({
        orderSearchMsg: {
          content: ''
        }
      })
      if (isSearchList) {
        this.assignOrderListState({
          orderSearchMsg: {
            isSearchList: false
          },
          orders: [],
          page: 1,
          noMoreData: false,
          status: {
            historyStatus: false,
            historyExtra: false,
            historyShow: false
          }
        })
        this.loadMore()
      }
    },
    orderSearch () {
      if (/^\s*$/.test(this.orderSearchMsg.content)) return
      this.reloadFindOrderDom()
      this.assignOrderListState({
        noMoreData: true,
        orders: [],
        status_type: 0,
        orderSearchMsg: {
          isSearchList: true,
          page: 1
        }
      })
      this.loadMore()
    },
    /** 头部功能 End */

    /** 数据请求 Start */
    getPageData () {
      // abt addressMixin 文件在使用
      this.isCodAddressEditEnable = this.abtInfos.isCodAddressEditEnable
      this.isInfoFrontAbt = this.abtInfos.isInfoFrontAbt
      this.isCanRefundAbt = this.abtInfos.isCanRefundAbt
      this.batchModifyAddressAbt = this.abtInfos.batchModifyAddressAbt
      this.showEditOrderAddress = this.abtInfos.showEditOrderAddress
      this.pageold = 1
      // 订单数据
      if (this.status_type) {
        this.updateByStatusOrderStatusEvt(this.unionData)
      } else {
        this.updateAllOrderStatusEvt(this.unionData)
      }
    },
    /** 数据请求 End */

    /** 埋点 Start */
    updateAllOrderStatusEvt (res) {
      if (res?.code == '0') {
        if (!this.orderSearchMsg.isSearchList) {
          SaPageInfo.page_param.order_count = res?.sum || 0
        }
        if (res?.currencySupport?.length) {
          this.assignState({ currencySupport: res.currencySupport })
        }
      } else {
        this.showMsg({
          type: 'err',
          msg: res?.tips || this.language?.SHEIN_KEY_PWA_15330 || ''
        })
      }
    },
    updateByStatusOrderStatusEvt (res) {
      if (res?.code == '0') {
        SaPageInfo.page_param.order_count = res?.sum || 0
        SaPageInfo.start_time = Date.now()
        window.SaPageInfo = SaPageInfo
        appEventCenter.$emit('pageOnload')
      } else {
        if (window.Navigator.onLine) {
          this.showMsg({
            type: 'err',
            msg: res?.msg || this.language?.SHEIN_KEY_PWA_15330
          })
        }
      }
    },
    /** 埋点 End */

    /** 列表数据请求 Start */
    async getOrdersListSer () {
      const { isSearchList, page: sPage, content: sKeyword } = this.orderSearchMsg || {}
      const languageKeys = Object?.keys(this.language || {})?.length ? 0 : 1
      const res = await isSearchList
        ? getBffDataBySearchKeyword({ page: sPage, keyword: sKeyword })
        : getBffListData({ page: this.page, fetchLangs: languageKeys })
      return res
    },
    getOrdersListSerCb (bffData) {
      const { order_list = [], isDemotion } = bffData
      this.updateAllOrderList({
        list: order_list,
        isDemotion
      })
      this.updateAllOrderStatusEvt(bffData)
    },
    async getOrderListByStatusSer () {
      const languageKeys = Object?.keys(this.language || {})?.length ? 0 : 1
      try {
        const res = await getBffListData({
          page: this.page,
          type: this.status_type,
          fetchLangs: languageKeys
        })
        return res
      } finally {
        this.setStatusLoading(false)
      }
    },
    async getOrderListByStatus() {
      const { loading } = this.status || {}
      if (loading) {
        return
      }
      this.setStatusLoading(true)
      const res = await this.getOrderListByStatusSer()
      this.getOrderListByStatusSerCb(res)
    },
    getOrderListByStatusSerCb (res) {
      const { order_list = [], isDemotion, topNotice = {}, is_have_gfcard_order, isShowPackage } = res || {}
      this.updateByStatusOrderList({
        list: order_list,
        isDemotion,
        topNoticeTips: topNotice?.tip || '',
        is_have_gfcard_order: is_have_gfcard_order || '',
        isShowPackage: isShowPackage || ''
      })
      this.updateByStatusOrderStatusEvt(res)
    },
    /** 列表数据请求 End */

    /** 加载更多 Start */
    async loadMore () {
      if (this.loadingMore) return
      this.loadingMore = true
      const { loading, historyShow } = this.status || {}
      if (this.status_type) {
        await this.getOrderListByStatus()
        if (window.navigator.onLine) {
          this.loadingMore = false
        }
        return
      }
      if (loading) return
      this.setStatusLoading(true)
      if (historyShow) {
        this.historyListFun(0)
        this.loadingMore = false
        return
      }
      const res = await this.getOrdersListSer()
      this.getOrdersListSerCb(res)
      this.loadingMore = false
    },
    /** 加载更多 End */

    /** 历史订单数据 */
    async historyListFun (type) {
      this.assignOrderListState({
        status: {
          historyExtra: false,
          historyStatus: false,
          historyShow: false
        }
      })
      this.setStatusLoading(true)
      if (this.pageold == 1) return this.getHistoryListSerCb({ data: this.historyOrders, type })
      const data = await getHistoryListSer({ page: this.pageold })
      this.getHistoryListSerCb({ data, type })
    },
    getHistoryListSerCb ({ data, type }) {
      this.updateHistoryOrderList({ data, page: this.pageold })
      this.pageold += 1
      if (type == 1 || type == 2) {
        sa('send', {
          activity_name: type == 1 ? 'click_view_history_order' : 'click_view_more_history_order',
          activity_param: ''
        })
      }
    },

    /** bff按钮相关新逻辑 start **/
    editAddressNewConfigOptionsEvt (order = {}) {
      return {
        billno: order.billno,
        editShippingAddressStyle: order?.operationInfo?.editShippingAddressStyle || {},
        shippingaddr_info: order?.shippingaddr_info || {},
        shipped_status_info: order?.shipped_status_info || {},
        editable_type: order?.shipped_status_info?.editable_type || '',
        combined_shipped_flag: order?.combined_shipped_flag || ''
      }
    },
    toDetailRecommendEvt(order = {}) {
      window.location.href = `${this.publicLangPath}/user/orders/detail/${order.billno}?recommend=1`
    },
    async showUrgeDelivery(order = {}) {
      this.setStatusLoading(true)
      this.orderInfo = order
      this.$nextTick(() => {
        this.$refs.urgeDeliveryModalRef.showUrgeDelivery()
      })
      this.setStatusLoading(false)
    },
    navToViewInvoices(order = {}) {
      this.$router.push({ path: `${this.publicLangPath}/user/order_invoices/${order.billno}` })
    },
    toRepurchaseEvt (order = {}, analysisData = {}) {
      this.orderInfo = order
      if (this.showNewRepurchaseSuccess) {
        this.handleRepurchase(order.billno)
      } else {
        this.repurchaseOrderBillno = order.billno
        this.repurchaseModel.show = true
      }
      daEventCenter.triggerNotice({
        daId: '1-20-1-142',
        extraData: analysisData
      })
    },
    navToMyReview (order = {}) {
      let { abTestConfig } = this.abtInfos || {}
      if (abTestConfig?.['MyReview'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.$router.push({ path: `${this.publicLangPath}/user/orders/review_already?billno=${order.billno}` })
    },
    handelTrackBtnClick(order = {}) {
      let { abTestConfig } = this.abtInfos || {}
      if (abTestConfig?.['Track'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.$router.push(`${this.publicLangPath}/user/orders/track/${order.billno}`)
    },
    handleExchangeOperation(order = {}) {
      let isGaryExchange = this.getSelfExchangeStatus(order)

      if (isGaryExchange) {
        this.disableExchangeTips = order?.order_button?.exchange_info?.gray_tip || ''
        this.showExchangeTipsPop = true
        daEventCenter.triggerNotice({
          daId: '1-20-1-99',
          extraData: {
            order_no: order.billno,
            reason: order?.order_button?.exchange_info?.nonExchangedReason || ''
          }
        })
        return
      }
      this.$router.push({
        path: `${this.publicLangPath}/orders/selfExchange?billno=${order.billno}`
      })
    },
    async urgeshipment(order = {}) {
      this.setStatusLoading(true)
      let res = await submitUrgeShipInfoSer(order.billno)
      this.setStatusLoading(false)

      if (res?.code != '0') {
        this.$toast(res?.msg)
        return
      }

      if ([1, 2]?.includes(res?.info?.urgeShippingStatus)) {
        this.urgeshipmentText = res?.info?.popUp || {}
        this.isShowUrgeShipmentDialog = true
      } else {
        this.$toast(res?.info?.failedTip || '')
      }
    },
    toEditReport(order = {}, source = '') {
      this.orderInfo = order

      daEventCenter.triggerNotice({
        daId: source == 'popup' ? '1-20-1-22' : '1-20-1-25',
        extraData: source == 'popup' ? { click: '1' } : {}
      })

      let { isCanUpload } = order || {}
      if (isCanUpload == 2) return this.$toast(this.language?.SHEIN_KEY_PWA_18092 || '')
      if (isCanUpload == 3) {
        let reportInfo = this.getReportInfo(order || {})
        this.$routerPush({
          name: 'post-report',
          params: {
            goods: {
              detail: {
                goods_img: reportInfo?.goods_img,
                goods_name: reportInfo?.goods_name,
                goods_sn: reportInfo?.goods_sn
              },
              id: order.apply_id,
              size_value: reportInfo?.size_value,
              attr_language_value: reportInfo?.attr_language_value,
              goods_sn: reportInfo?.goods_sn,
              billno: order.billno
            }
          },
          query: {
            from: 'order_list'
          }
        })
      }
    },
    getReportInfo(order = {}) {
      const [orderData = {}] = order?.orderGoodsList?.flat(Infinity) || []
      return {
        goods_img: orderData?.goods_img,
        goods_name: orderData?.goods_name,
        goods_sn: orderData?.goods_sn,
        size_value: orderData?.goods_attr,
        attr_language_value: orderData?.sku_attrs_contact_str
      }
    },
    getSelfExchangeStatus(order = {}) {
      const { order_button = {} } = order || {}

      return order_button?.exchange_info?.can_exchange == 2
    },
    getCancelDisabledBtn(order = {}) {
      const { orderStatus, operationInfo = {} } = order || {}

      return orderStatus == 4 ? operationInfo?.partRefundStyle?.style == 'gray' : operationInfo?.orderRefundStyle?.style == 'gray'
    },
    getCancelOrderDisabledBtn(order = {}) {
      const { operationInfo } = order || {}

      return operationInfo?.orderRefundStyle?.style == 'gray'
    },
    showPointsTipEvt (order = {}) {
      let { repurchase_lure_info = {}, billno = '' } = order || {}

      return !this.riskCommentArr?.includes(billno) && !(this.showNewRepurchase && !!repurchase_lure_info?.tip)
    },
    async navToReview(order = {}) {
      let { abTestConfig } = this.abtInfos || {}
      if (abTestConfig?.['Review'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.currentCommentOrder = order?.billno
      let params = {
        billno: order.billno,
        blackbox: window._fmOpt?.__blackbox || ''
      }
      let { info = {} } = await getReviewRiskSer(params)
      let riskRejectTitle = info?.orderReviewPopupInfo?.riskRejectTitle
      let riskResult = info?.productReviewCheckResult
      if (riskResult == 1 && riskRejectTitle != 'null') {
        this.showRiskCommentTips.show = true
        this.showRiskCommentTips.info = info
        daEventCenter.triggerNotice({
          daId: '1-20-1-130',
          extraData: {
            order_no: order.billno,
          }
        })
        return
      }

      // 传给订单评论的缓存
      const { billno = '', orderGoodsList = [], relationBillno = '' } = order || {}
      const sku_code_list = orderGoodsList.map(v => v?.goods_sn || '')
      const goods_id_list = orderGoodsList.map(v => v.goods_id)
      const goods_name_list = orderGoodsList.map(v => v?.goods_name || '')
      const biz_id = `${new Date().getTime()}_${billno}`
      const riskData = {
        risk_id: info.risk_id || '',
        billno: billno,
        biz_id: biz_id,
        sku_code_list: sku_code_list,
        goods_id_list: goods_id_list,
        goods_name_list: goods_name_list,
        order_status: order.order_status,
        blackbox: window._fmOpt?.__blackbox || '',
        rateStatus: info.risk_decision == '3621' ? 0 : 1
      }
      setLocalStorage({ key: 'risk_review_data', value: riskData })

      // 跳转到订单评论
      this.$router.push({
        path: `${this.publicLangPath}/user/orders/order_review?billno=${billno}&relationBillno=${relationBillno}&source=order&orderType=sonOrder`
      })
    },
    showEditAddressLink(order = {}) {
      if (!this.showOrderListPromotionTest) return false
      return [0, 12].includes(+order.orderStatus)
    },
    async orderRefund(order) {
      this.orderInfo = order
      
      let { abTestConfig } = this.abtInfos || {}
      if (abTestConfig?.['UnshippedCancelOrder'] === 'on') {
        this.toggleUnavailableModal()
        return
      }

      if (this.getCancelOrderDisabledBtn(order)) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-44',
          extraData: {
            operation_from: 0
          }
        })
        this.showFrontConditionText = order?.operationInfo?.partRefundStyle?.tip || ''
        this.showFrontConditionDialog = true
        return
      }

      this.assignOrderListState({
        status: { msgLoading: true }
      })
      let resInfo = await isSupportDistributionCountrySer(order.billno)
      this.assignOrderListState({
        status: { msgLoading: false }
      })
      if (resInfo?.info?.changeSite) {
        const { shipping_country_name, shipping_country_id } = order.shippingaddr_info || {}
        return this.jumpMask({
          url: '/user/orders/list',
          result: 0,
          shipping_country_name,
          shipping_country_id
        })
      }

      this.refundNotCodMask = true
    },
    async toRevokeOrder(order = {}) {
      this.orderInfo = order
      daEventCenter.triggerNotice({
        daId: '1-20-1-27',
        extraData: {
          billno: order.billno
        }
      })

      let { abTestConfig } = this.abtInfos || {}
      if (abTestConfig?.['Revoke'] === 'on') {
        this.toggleUnavailableModal()
        return
      }

      if (this.getCancelDisabledBtn(order)) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-44',
          extraData: {
            operation_from: 0
          }
        })
        this.showFrontConditionText = order?.operationInfo?.partRefundStyle?.tip || ''
        this.showFrontConditionDialog = true
        return
      }

      let resInfo = await isSupportDistributionCountrySer(order.billno)
      if (resInfo?.info?.changeSite) {
        const { shipping_country_name, shipping_country_id } = order.shippingaddr_info || {}
        return this.jumpMask({
          url: '/user/orders/list',
          result: 0,
          shipping_country_name,
          shipping_country_id
        })
      }

      if ([4, 6].includes(+order?.orderStatus)) {
        this.codCancelItemTipVisible = true
      }
    },
    async confirmDeliverAlterFun(order = {}) {
      this.orderInfo = order

      let { abTestConfig } = this.abtInfos || {}
      if (abTestConfig?.['ConfirmDelivery'] === 'on') {
        this.toggleUnavailableModal()
        return
      }

      let { receive_msg } = order || {}
      if (!!receive_msg) {
        this.timeTips = receive_msg
        this.isShowTimeTipsDialog = true
        daEventCenter.triggerNotice({
          daId: '1-20-1-43',
          extraData: {
            billno: order.billno
          }
        })
        return
      }

      if (order.payment_method == 'cod') {
        this.checkCodOrderIsSupportReceipt(order)
      } else {
        this.checkNoCodOrderIsSupportReceipt(order)
      }
    },
    // 检查非cod订单是否确认收货
    async checkNoCodOrderIsSupportReceipt(order) {
      let result = await confirmDeliveryTipsSer(order.billno)
      if (result?.code == '0') {
        this.checkCommonEvt(result)
      } else {
        this.errorCommonEvt(result)
      }
    },
    // 检查cod订单是否确认收货
    async checkCodOrderIsSupportReceipt(order) {
      let { confirmDeliveryBonusPoints } = order || {}
      let result = await checkOrderIsSupportReceiptSer(order.billno)
      if (result?.code == '0') {
        if (result?.info?.confirmable_receipt) {
          this.checkCommonEvt(result)
        } else {
          this.codOrderIsNotSupportReceiptTip = this.language?.SHEIN_KEY_PWA_16583?.replace('[num]', confirmDeliveryBonusPoints)
          this.codOrderIsNotSupportReceipt = true
        }
      } else {
        this.errorCommonEvt(result)
      }
    },
    async checkCommonEvt(result) {
      this.showConfirmDeliveryTips = true
      this.confirmDeliveryGoodsInfo = result?.info || {}

      daEventCenter.triggerNotice({
        daId: '1-20-1-12'
      })
    },
    errorCommonEvt (result = {}) {
      this.$toast(result?.msg || '')
      sa('send', {
        activity_name: 'expose_popup_confirm_error',
        activity_param: {}
      })
    },
    orderInducementType(payNowLabelList) {
      if (!Array.isArray(payNowLabelList) || !payNowLabelList.length) return ''
      const labelMap = {
        1: 'save',
        2: 'freeShipping',
        3: 'freeReturn',
        4: 'priceGuarantee'
      }
      const labelList = payNowLabelList.map(item => {
        return labelMap[item.inducementType] || ''
      })
      return labelList.join(',') 
    },
    handleBtnMoreItem(item) {
      item.el.click()
    },
    handleMyReviewShow (myReview) {
      this.$refs[myReview][0].style.display = this.$refs[myReview]?.[0]?.style.display == 'block' ? 'none' : 'block'
    },
    getReturnItemBtnInfo (orderBtnMore = {}) {
      let isShowReturnItem = orderBtnMore?.btnMoreList?.find(item => item?.el?.[0]?.name == 'returnItem')
      return isShowReturnItem ? 'more' : 'outside'
    },
    async handleReturnItemEvt (order = {}, orderBtnMore = {}) {
      this.orderInfo = order || {}
      this.returnItemBtnPosition = this.getReturnItemBtnInfo(orderBtnMore)
      daEventCenter.triggerNotice({
        daId: '1-20-1-171',
        extraData: {
          position: this.returnItemBtnPosition
        }
      })
      
      let { isReturnOff } = this.abtInfos || {}
      if (isReturnOff) {
        this.returnItemEventData('other')
        this.toggleReturnUnavailableModal()
        return
      }

      let resInfo = await isSupportDistributionCountrySer(order.billno)
      if (resInfo?.info?.changeSite) {
        this.returnItemEventData('other')

        const { shipping_country_name, shipping_country_id } = order.shippingaddr_info || {}
        return this.jumpMask({
          url: `/user/orders/list`,
          result: 0,
          shipping_country_name,
          shipping_country_id
        })
      }

      let returnInfo = await orderReturnableSer({ billno: order.billno })
      if (returnInfo?.code == '0') {
        let { returnableStatus, over_time_limit, returnTimeoutPopup, doublePopup, isDowngrade, is_signed, notSignedTip, downgradeTip, overTimeLimitTip } = returnInfo?.info || {}
        if (returnableStatus != 1) {
          this.returnItemEventData('other')

          this.showReturnItemTips.show = true
          this.showReturnItemTips.text = this.language?.SHEIN_KEY_PWA_16202 || ''
        } else {
          if (over_time_limit == 1) {
            this.returnItemEventData('overtime')

            if (returnTimeoutPopup != null) {
              this.isExpirePop.show = true
              this.isExpirePop.info = returnTimeoutPopup || {}
            } else {
              sa('send', {
                activity_name: 'expose_popup_non_returnable',
                activity_param: {}
              })
              this.showReturnItemTips.show = true
              this.showReturnItemTips.text = overTimeLimitTip || ''
              this.showReturnItemTips.type = 1
            }
          } else if (isDowngrade == 1) {
            this.returnItemEventData('other')

            this.showReturnItemTips.show = true
            this.showReturnItemTips.text = downgradeTip || ''
          } else if (is_signed != 1) {
            this.returnItemEventData('undelivered')
            if (doublePopup != null) {
              this.isSignPop.show = true
              this.isSignPop.info = doublePopup || {}
            } else {
              this.showReturnItemTips.show = true
              this.showReturnItemTips.text = notSignedTip || ''
              this.showReturnItemTips.type = 2
            }
          } else {
            this.returnItemEventData('alldelivered')
            this.returnItemJumpEventData('alldelivered')

            this.$router.push(`${this.publicLangPath}/orders/return/${order.billno}?pf=listordetail`)
          }
        }
      } else {
        this.returnItemEventData('other')

        this.showReturnItemTips.show = true
        this.showReturnItemTips.text = returnInfo?.msg || this.language?.SHEIN_KEY_PWA_14909 || ''
      }
    },
    confirmReturnEvt () {
      this.isSignPop.show = false
      this.returnItemJumpEventData('undelivered')

      this.$router.push(`${this.publicLangPath}/orders/return/${this.orderInfo.billno}?pf=listordetail`)
    },
    returnItemJumpEventData (scene = '') {
      daEventCenter.triggerNotice({
        daId: '1-20-1-174',
        extraData: {
          position: this.returnItemBtnPosition,
          scene
        }
      })
    },
    returnItemEventData (scene = '') {
      daEventCenter.triggerNotice({
        daId: '1-20-1-172',
        extraData: {
          scene
        }
      })
    },
    handleReturnItemTipsOk () {
      this.showReturnItemTips.show = false
      if (this.showReturnItemTips.type == 1) {
        sa('send', {
          activity_name: 'click_popup_non_returnable_ok',
          activity_param: {}
        })
      }
      if (this.showReturnItemTips.type == 2) {
        this.$router.push(`${this.publicLangPath}/orders/return/${this.orderInfo.billno}?pf=listordetail`)
      }
    },
    showUrgePickUpEvt(order = {}) {
      return order?.order_button?.urgent_pick_up_info?.urge_pick_up == 1
    },
    /** bff按钮相关新逻辑 end **/

    /*
     *根据不同的订单状态操作按钮展示不同的顺序
     */
    opeButtonSort(order = {}) {
      let buttons = getOrderButtonSorts(order)
      if (!buttons.length) return []

      let btns = new Set(buttons)
      let { orderStatus, payment_method, isCanConfirmDelivery, canRepurchase, isCanComment, isCanUpload, is_have_invoice, track_h5_link, isCanReturn, isCommonOrderCanRefund, isCanRevokeByUser, shippingaddr_info = {}, could_ocb_order, order_button = {}, newReturnItemWebUrl, shipped_status_info = {} } = order
      let { orderListAbtInfo = [], abTestConfig = {}, uploadReportShow, isOpenViewInvoicesByAbt, isCodAddressEditEnable } = this.abtInfos || {}

      // repurchase
      if (!(canRepurchase == 1 && abTestConfig?.['Repurchase'] != 'on')) {
        btns.delete('repurchase')
      }

      // review
      if (![1, 3].includes(+isCanComment)) {
        btns.delete('review')
      }

      // my_review
      if (![2, 3].includes(+isCanComment)) {
        btns.delete('my_review')
      }

      // upload report
      if (!([2, 3].includes(+isCanUpload) && uploadReportShow)) {
        btns.delete('upload_report')
      }

      // view invoices
      if (!(is_have_invoice == 1 && isOpenViewInvoicesByAbt)) {
        btns.delete('view_invoices')
      }

      // 物流轨迹
      if (!track_h5_link) {
        btns.delete('track')
      }

      // return_item
      if (!(isCanReturn == 1 && orderListAbtInfo?.includes('returnitem=on') && !!newReturnItemWebUrl)) {
        btns.delete('return_item')
      }

      // 已付款未发货取消订单申请退款
      if (isCommonOrderCanRefund != 1) {
        btns.delete('cancel_order')
      }

      // revoke
      if (isCanRevokeByUser != 1) {
        btns.delete('revoke')
      }
      
      // in站不能在ar站短信验证
      if (!([13].includes(+orderStatus) && orderListAbtInfo?.includes('verify=on'))) {
        btns.delete('verify_now')
      }

      // pay now && Other Payment Method
      if (!([0, 12].includes(+orderStatus) && orderListAbtInfo?.includes('pay=on'))) {
        btns.delete('pay_now')
      }

      // 编辑地址按钮新旧
      let showAbtValue = payment_method == 'cod' ? isCodAddressEditEnable : !orderListAbtInfo?.includes('address=off')
      // 新流程
      let isNewEditAddress = shipped_status_info?.new_edit_address == 1 && shippingaddr_info.isOrderShippingAddressEditable == 1
      // 旧流程
      let isOldEditAddress = shippingaddr_info.isOrderShippingAddressEditable == 1 && showAbtValue
      if (!(isNewEditAddress || isOldEditAddress)) {
        btns.delete('edit_address')
      }

      // confirm delivery
      if (isCanConfirmDelivery != 1) {
        btns.delete('confirm_delivery')
      }

      // one click pay
      if (could_ocb_order != 1) {
        btns.delete('one_click_pay')
      }
      // 催派
      if (order_button.urge_delivery != 1) {
        btns.delete('urge_delivery')
      }
      // 催取件 urgent_pickup
      if (order_button?.urgent_pick_up_info?.urge_pick_up != 1){
        btns.delete('urgent_pickup')
      }
      // urge_shipment
      if (order_button.urge_shipping != 1) {
        btns.delete('urge_shipment')
      }
      // exchange
      if (![1, 2].includes(+order_button?.exchange_info?.can_exchange)) {
        btns.delete('exchange')
      }
      // 自主标记
      if (!this.markOrderShow(order)) {
        btns.delete('resume_shipment')
      }  
      return [...btns]
    },
    isShowSelfExchange(order) {
      const { orderStatus = '', can_exchange } = order
      if (orderStatus != 4 || !this.abtInfos.isShowExchangeAbt) return false

      return can_exchange != 0
    },
    isShowUrgeshipment(order) {
      const { orderStatus = '', isUrgeShipExpedite = false } = order || {}
      if (![1, 4, 6].includes(+orderStatus) || !this.abtInfos.isShowUrgeshipmentAbt) return false

      return !!isUrgeShipExpedite
    },
    getBtnMoreAnalysis(item) {
      let exposeInfo = item?.el?.[0]?.getAttribute('expose-info') || '{}'
      try {
        exposeInfo = JSON.parse(exposeInfo)
      } catch (e) {
        exposeInfo = {}
      }

      if (exposeInfo.hasOwnProperty('is_promoshow')) {
        exposeInfo.is_promoshow = 0
      }

      return exposeInfo || {}
    },
    showExpireCountDown(order = {}) {
      const { orderStatus, order_expire_time, addTime } = order || {}
      const isOverCurrentTime = Number(order_expire_time) * 1000 < Number(new Date().getTime())
      const isRangeTime = Number(addTime) < Number(order_expire_time)

      // 如果小于当前时间, 说明已经过期, 不显示
      if (isOverCurrentTime) return false

      return (
        [0, 12, 13].includes(orderStatus) &&
        this.abtInfos.orderListAbtInfo.includes('paytime=on') &&
        isRangeTime
      )
    },
    orderBusiModel(order = {}) {
      const tempGoodsList = order?.orderGoodsList?.flat(Infinity) || []
      const busiModelList = tempGoodsList?.map(list => +list.business_model)
      if (busiModelList.includes(1) && busiModelList.includes(0)) return 3
      if (busiModelList.includes(1)) return 2
      return 1
    },
    orderBuyTpInfo(order) {
      const tempGoodsList = order?.orderGoodsList?.flat(Infinity) || []
      return orderLogic.getBuyTpInfo(order, tempGoodsList)
    },
    showMsg (params = {}) {
      const { msg, type, timer = 1500 } = params
      this.assignOrderListState({
        status: {
          msg: true,
        }
      })
      this.msgBox.info = msg
      this.msgBox.type = type
      return new Promise((resolve) => {
        setTimeout(() => {
          this.assignOrderListState({
            status: {
              msg: false,
            }
          })
          resolve()
        }, timer)
      })
    },
    handleMethod () {
      this.handleSave = orderFactory.createOrderToCart(this, 'order_list')
    },
    setStatusLoading(v) {
      // 只允许一种loading出现
      if (this.pageLoading) return
      this.assignOrderListState({
        status: {
          loading: v
        }
      })
    },
    jumpToRobot() {
      let billno = this.orders?.[0]?.billno || ''
      let paramsStr = billno ? `&billno=${billno}` : ''
      return robotLinkCommon('orderListPage', paramsStr)
    },
    //回收站功能上线后首次删除订单则展示入口提示气泡
    showDeleteHistoryPopoverHandle() {
      try {
        const isFirstDeleteOrder = window.localStorage.getItem('firstDeleteOrder')
        if (isFirstDeleteOrder && isFirstDeleteOrder == 1) {
          this.showDeleteHistoryPopover = true
          const timer = setTimeout(() => {
            this.showDeleteHistoryPopover = false
            window.clearTimeout(timer)
            window.localStorage.setItem('firstDeleteOrder', 2)
          }, 3500)
        }
      } catch (e) {
        console.info(e)
      }
    },

    /** 状态切换 Start */
    handleTabChange(val) {
      if (this.status_type != val) {
        this.reloadFindOrderDom()
        this.statusFilterClick(val)
      }
    },
    reloadFindOrderDom(){
      // 切换tab 刷新曝光埋点
      this.showFindOrderFlag = false
      this.$nextTick(()=>{
        this.showFindOrderFlag = true
      })
    },
    async statusFilterClick(type) {
      this.assignOrderListState({
        status: {
          historyStatus: false,
          historyExtra: false,
          historyShow: false
        },
        noMoreData: false,
        status_type: type,
        page: 1,
        orders: []
      })
      this.assignRootState({ statusType: type })
      this.setStoreState({ statusType: 0 }) // 清除个人中心状态
      await this.getOrderListByStatus()
    },
    /** 状态切换 End */
    async orderRefundMask() {
      const { billno } = this.orderInfo || {}
      daEventCenter.triggerNotice({
        daId: '1-20-1-21',
        extraData: {
          order_id: billno
        }
      })
      if (this.showRefundNew) {
        this.handleShowCancelItemSelect()
      } else {
        this.jumpRefund(this.orderInfo || {})
      }
    },
    // 已支付整单取消
    async jumpRefund (item = {}) {
      const { billno, payment_method } = item
      this.refundNotCodMask = false
      this.$router.push({ path: `${this.publicLangPath}/user/orders/refund/${billno}?paymentMethod=${payment_method}&type=1` })
    },
    // 进入cancel_item弹窗
    handleShowCancelItemSelect() {
      this.refundNotCodMask = false
      this.changeRootStatus({ loading: true })
      this.fetchOrderInfo()
    },
    async fetchOrderInfo() {
      const { billno } = this.orderInfo || {}
      const data = await get_order_info_ser(billno)
      this.changeRootStatus({ loading: false })
      if (data?.info?.order) {
        this.codCancelItemTipVisible = false
        this.assignCancelItemState({
          visibleMainDrawer: true,
          orderInfo: data?.info?.order,
          language: this.language
        })
      } else {
        this.assignCancelItemState({
          orderInfo: {}
        })
      }
    },
    confirmDialogHandle() {
      daEventCenter.triggerNotice({
        daId: '2-30-1',
        extraData: {
          name: 'expose_sync_to_useraddress_pop'
        }
      })
      this.showConfirmDialog = true
    },
    async handleRepurchase (billno) {
      const successTips = false
      this.handleSave(
        billno,
        res => {
          if (this.showNewRepurchaseSuccess && res?.code == '0') {
            this.addCartStatus.showDrawer = true
            this.addCartStatus.cartInfo = {
              successInfoList: res.info?.success_info_list || [],
              falidInfoList: res.info?.falid_info_list || [],
              repurchaseInfo: this.orderInfo.repurchase_lure_info
            }
          } else if (res?.code == '0' && !successTips) {
            this.successPanelData.show = true
            this.successPanelData.billno = billno
          }
        },
        '',
        { successTips }
      )
    },
    async preCheckPointByConfirmOrder() {
      const { billno } = this.orderInfo || {}
      daEventCenter.triggerNotice({
        daId: '1-20-1-13',
        extraData: {
          order_id: billno,
          result: ''
        }
      })

      let resInfo = await confirmDeliverySer(billno)

      this.showConfirmDeliveryTips = false
      if (resInfo?.info?.isPointFusing == 1) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-9'
        })
        this.preCheckPointByConfirmInfo = resInfo?.info || {}
        this.orderDeliveryPointCheckTips.text = resInfo?.info?.deliveryMsg || ''
        this.orderDeliveryPointCheckTips.showModal = true
      } else {
        this.toDeliverySuccessPanel(this.orderInfo)
      }
    },
    async toDeliverySuccessPanel(order = {}) {
      this.$router.push({
        path: `${this.publicLangPath}/user/orders/state?type=delivery&billno=${order.billno}`
      })
    },
    cancleConfirmOrder() {
      this.showConfirmDeliveryTips = false
      daEventCenter.triggerNotice({
        daId: '1-20-1-14',
        extraData: {
          order_id: this.orderInfo.billno
        }
      })
    },
    closeRiskCommentTips() {
      if (!this.riskCommentArr.includes(this.currentCommentOrder)) {
        this.riskCommentArr.push(this.currentCommentOrder)
      }
    },
    handleShowCodCancelItemSelect() {
      this.changeRootStatus({ loading: true })
      this.fetchOrderInfo()
    },
    // 合单状态是否为混发弹窗
    showCombinedStatus({ billno, status, merge_buy_billno }) {
      this.combinedOrderModal.show = true
      this.combinedOrderModal.billno = billno
      this.combinedOrderModal.merge_buy_billno = merge_buy_billno
      this.combinedOrderModal.tips = status ? this.language.SHEIN_KEY_PWA_14588 : this.language.SHEIN_KEY_PWA_14587
    },
    showCombinedReturnEvt ({ combined_return_msg = '' } = {}) {
      this.showCombinedReturnText = combined_return_msg
      this.showCombinedReturn = true
    },
    navToDetail(billno) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-31',
        extraData: {
          billno
        }
      })
      this.combinedOrderModal.show = false
      this.$router.push(`${this.publicLangPath}/user/orders/detail/${billno}`)
    },
    cancelPreCheckConfirmDeliver() {
      this.orderDeliveryPointCheckTips.showModal = false
      daEventCenter.triggerNotice({
        daId: '1-20-1-11'
      })
    },
    async confirmDeliver(order) {
      this.orderDeliveryPointCheckTips.showModal = false
      this.assignOrderListState({ status: { msgLoading: true } })

      let resInfo = await confirmDeliveryOfNewSer({
        billno: order.billno,
        isPointFusing: this.preCheckPointByConfirmInfo.isPointFusing,
        maxIntergral: this.preCheckPointByConfirmInfo.max_available_point
      })
      this.assignOrderListState({ status: { msgLoading: false } })
      if (resInfo?.code == '0') {
        let { confirmDeliverySucTip, confirmSuccessTip } = this.confirmDeliveryGoodsInfo || {}
        let { deliveryMsg = '', isCanComment } = resInfo?.info || {}

        if (!!deliveryMsg) {
          this.$toast({
            htmlString: deliveryMsg,
            onClose: () => {
              location.reload()
            }
          })
        } else {
          let successTip = [1, 3].includes(+isCanComment) ? confirmDeliverySucTip : confirmSuccessTip
          this.confirmDeliverMessageText = successTip
          this.confirmDeliverMessageType = +isCanComment
          this.confirmDeliverMessage = true
        }
      } else {
        this.$toast(resInfo?.msg)
      }
    },
    confirmDeliverMessageEvt() {
      this.confirmDeliverMessage = false

      if ([1, 3].includes(+this.confirmDeliverMessageType)) {
        let { billno = '', relationBillno = '' } = this.orderInfo || {}
        sa('send', {
          activity_name: 'click_go_to_write_a_review',
          activity_param: { order_id: billno }
        })
        this.$router.push({
          path: `${this.publicLangPath}/user/orders/order_review?billno=${billno}&relationBillno=${relationBillno}&isRealTime=1&source=order&orderType=sonOrder`
        })
      }
    },
    urgeReturnEvt (order) {
      const reportInfo = {
        daId: '1-20-1-156',
        bindData: {
          order_no: order.billno,
          button_status: 1,
          result: ''
        }
      }
      this.$refs.urgentPickUp?.initUrgeEvt({
        urgentList: order.order_button?.urgent_pick_up_info?.return_order_list,
        billno: order.billno,
        reportInfo
      })
    },
    // 新的支付流程 Start
    async newPayNowOrVerifyNowEvt (order = {}, { payType }) {
      let { payment_type = '', billno = '', shippingaddr_info = {}, order_expire_time, is_virtual_order } = order || {}
      let overExpireTime = +order_expire_time  <= (Date.parse(new Date()) / 1000)

      // 是否过了未支付时间
      if (+payment_type == 2 && overExpireTime) {
        daEventCenter.triggerNotice({ daId: '1-20-1-4' })
        this.expiredModalConfig = {
          show: true,
          order
        }
        return
      }
      // 是否触发切站
      let resInfo = await isSupportDistributionCountrySer(billno)
      if (resInfo?.info?.changeSite) {
        let { shipping_country_name, shipping_country_id } = shippingaddr_info || {}
        return this.jumpMask({
          url: '/user/orders/list',
          result: 0,
          shipping_country_name,
          shipping_country_id
        })
      }

      this.changeRootStatus({ loading: true })
      await this.fetchBffOrderInfo({ billno, is_virtual_order })
      this.changeRootStatus({ loading: false })
      this.assignOldOrderInfo(order)

      if (payType == 1) {
        this.handleEditPayment()
      } else {
        this.handleDirectCreatePayment()
      }
    },
    async toggleOrderExpiredModal({ type = '' } = {}) {
      let eventMap = {
        'cancel': '1-20-1-5',
        'ok': '1-20-1-6',
      }
      daEventCenter.triggerNotice({ daId: eventMap[type] })

      this.expiredModalConfig.show = false

      if (type === 'cancel') {
        let { billno = '', is_virtual_order } = this.expiredModalConfig?.order || {}
        await this.fetchBffOrderInfo({ billno, is_virtual_order })
        this.handleEditPayment()
      }
    },
    handleCloseDrawer () {
      this.changePageStatus({ showPaymentsDrawer: false })
    },
    handleManualCloseDrawer () {
      this.changePageStatus({ showPaymentsDrawer: false })
    },
    /**
     * 初始化支付方式选择
     * 
     * {
     *    paymentInfo: PaymentInfoItem, // 当前选择的支付方式信息
     * }
     */
    handleInitSelectPayment ({
      paymentInfo = {}
    } = {}) {
      console.log('order_new==handleInitSelectPayment==', paymentInfo)
      this.awaitInitSelectPaymentInfo({
        paymentInfo
      })
      if (paymentInfo?.code) {
        this.handleSetPayToolkitFn()
      }
    },
    /**
     * 当前选择的支付方式
     * 
     * {
     *   paymentInfo: PaymentInfoItem, // 当前选择的支付方式信息
     *   isChange: boolean, // 是否切换了支付方式
     * }
     */
    async handleSelectPaymentInfo ({ paymentInfo = {}, isChange } = {}) {
      console.log('order_new==handleSelectPayment==', { paymentInfo, isChange })
      if (!isChange) return
      let { billno = '', is_virtual_order, orderCurrency = {} } = this.bffOrderInfo || {}
      console.log(this.bffOrderInfo, 11111)
      let { code = '', id = '', enabled, card_token_info = {}, token_id = '', isUseNewCard = false  } = paymentInfo || {}

      const nowTokenId = isUseNewCard ? '' : card_token_info?.id || token_id || ''
      const nowBin = isUseNewCard ? '' : card_token_info?.card_bin || ''

      if (!enabled) return
      this.changeRootStatus({ loading: true })

      const paymentOptionsDrawerRef = this.$refs?.paymentOptionsDrawerRef

      let resInfo = await switchPaymentSer({
        billno,
        editType: 'editOrderPaymentMethod',
        payment_id: id,
        payment_code_unique: code,
        appCurrency: orderCurrency?.code || '',
        tokenId: nowTokenId,
        bin: nowBin,
      })

      if (resInfo?.code == '0') {
        // switchPayment成功后更新支付数据
        this.handleSelectPayment(paymentInfo)
        this.handleSetPayToolkitFn()

        // 更新订单信息
        await Promise.all([
          this.refreshOrderInfo(billno).catch(() => {}),
          this.fetchBffOrderInfo({ billno, is_virtual_order, tokenId: nowTokenId, }).catch(() => {})
        ])
        this.handleUpdatePayment()
      } else {
        paymentOptionsDrawerRef.resetBackPrevSelectedPayment()
        this.$toast(resInfo?.msg)
      }
      this.changeRootStatus({ loading: false })
      return resInfo
    },
    handleSelectPaymentChange (data) {
      console.log('order_new==handleSelectPaymentChange==', data)
      this.handleUpdatePaymentChange(data.paymentInfo)
      this.handleSetPayToolkitFn()
    },
    // 支付方法
    async handleConfirmPayNow ({
      paymentInfo = {},
      isChange = false,
      extraInfo = {}
    } = {}) {
      console.log('order_new==handleConfirmPayNow==', paymentInfo, extraInfo)
      this.changePageStatus({ showPaymentsDrawer: false })
      // this.handleStartPaymentProcess({ extraPayInfo: extraInfo })

      if (isChange) {
        await this.handleSelectPaymentInfo({ paymentInfo })
        this.handleStartPaymentProcess({ extraPayInfo: extraInfo })
      } else {
        this.handleStartPaymentProcess({ extraPayInfo: extraInfo })
      }
    },
    handleSetPayToolkitFn () {
      const fn = this.$refs?.paymentOptionsDrawerRef?.getValidatedPayment?.()
      const resetFn = this.$refs?.paymentOptionsDrawerRef?.getResetBsPayTookitStatusFn?.()
      this.handleSetValidateByPayFn(fn)
      this.handleSetResetBsPayTookitStatusFn(resetFn)
    },
    // 新的支付流程 End
  }
}
</script>

<style lang="less">
/* stylelint-disable */
@import '../style/list.less';
</style>

<style lang="less" scoped>
/* stylelint-disable */
.wait-send-return {
  position: sticky;
  top: 1.1733rem;
  z-index: @zindex-header;
}
.network-error {
  position: sticky;
  top: 1.1733rem;
  z-index: @zindex-header;
}
</style>
